@charset "UTF-8";
/*==========  Desktop First Method  ==========*/

@media only screen and (max-width : 1480px) {
  .intro__img_1 {
    left: 0;
  }
}

@media only screen and (max-width : 1380px) {
  .intro__row_bot {
    padding: 30px 0 100px;
  }
  .intro__sl .slick-dots {
    z-index: 2;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
    top: auto;left: 0;bottom: 40px;
  }
  .intro__sl .slick-dots li {
    margin: 0 10px;
    display: inline-block;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
  .header .container {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .nav {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    padding: 0;
    width: 100%;
    margin-top: 25px;
  }
  .nav__list {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .intro__head img {
    width: 140px;height: 25px;
  }
  .intro__txt {
    font-size: 30px;
  }
  .preparation {
    padding: 60px 0;
  }
  .title__h2 {
    font-size: 32px;
  }
  .pharmacy {
    padding: 50px 0;
  }
  .pharmacy .title {
    max-width: 670px;
  }
  .information {
    padding: 50px 0;
  }
  .information__sl .slick-arrow {
    top: -72px;
  }
  .footer__text {
    margin-top: 0;
  }
  .info__box {
    padding-left: 20px;
  }
  .useful {
    padding: 40px 0 50px;
  }
  .useful__txt {
    padding-right: 0;
  }
  .info-title {
    font-size: 26px;
    padding: 12px 15px;
  }
  .disease__item {
    margin-top: 30px;
  }
  .functions .row [class*='col']:not(:last-child) {
    margin-bottom: 5px;
  }
  .exercises {
    padding: 50px 0;
  }
  .faq {
    padding: 40px 0;
  }
  .accordion-header {
    font-size: 16px;
    padding: 10px 20px 10px;
  }
  .accordion__icon {
    font-size: 16px;
    width: 40px;height: 40px;
  }
  .accordion-body {
    padding: 30px 20px 20px;
  }
  .accordion-body p:not(:last-child) {
    margin-bottom: 15px;
  }
  .selling {
    padding: 50px 0 30px;
  }
  .contacts {
    padding: 50px 0;
  }
  .contacts__box {
    padding-right: 0;
  }
  .contacts__form {
    height: auto;
    margin-top: 45px;
  }
  .contacts__form .form .col-xl-7 {
    margin-top: 15px;
  }
  .form__field textarea {
    padding-left: 50px;
  }
  .about {
    padding: 50px 0;
  }
  .about .row [class*='col'] {
    margin-bottom: 30px;
  }
  .desc {
    padding: 50px 0;
  }
  .desc .box {
    padding: 40px 20px;
  }
  .specialists {
    padding: 45px 0 30px;
  }
}
 
/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
  .header {
    padding: 12px 0;
  }
  .header .container {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .nav {
    z-index: 2;
    display: none;
    margin-top: 0;
    -webkit-box-ordinal-group: inherit;
        -ms-flex-order: inherit;
            order: inherit;
    padding: 25px 0;
    position: absolute;
    background: #ebf2f7;
    top: 77px;left: 0;right: 0;
    border-top: 1px solid rgba(10, 75, 120, 0.1);
    border-bottom: 1px solid rgba(10, 75, 120, 0.1);
  }
  .nav__list {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 720px;width: 100%;
  }
  .nav__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .nav__link {
    display: inline-block;
  }
  .nav__link:after {
    width: 100%;
  }
  .navbar-toggle {
    display: block;
  }
  .intro__img {
    position: absolute;
    top: 0;left: 0;right: 0;
  }
  .intro__img img {
    height: 160px;
    margin-left: auto;
  }
  .intro__sl__img {
    display: none;
  }
  .intro__head {
    margin-top: 35px;
  }
  .information__txt h3 {
    font-size: 22px;
  }
  .information__txt p {
    margin-top: 10px;
  }
  .footer {
    padding-top: 45px;
  }
  .footer .row [class*='col'] {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .footer .row [class*='col']:last-child {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .info__box {
    padding-left: 0;
  }
  .useful__img {
    margin-top: 20px;
  }
  .disease__item_2 .row {
    margin-top: 0;
  }
  .disease__item_2 .row .col-xl-4,
  .disease__item_4 .row .col-xl-4 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-top: 20px;
  }
  .functions, .caution {
    padding: 20px;
  }
  .exercises__item {
    padding: 30px;
  }
  .exercises__box {
    padding-left: 0;
  }
  .xercises__img {
    padding: 0;
    margin-top: 20px;
  }
  .selling .box:not(:last-child) {
    margin-bottom: 40px;
  }
  .about__txt {
    font-size: 16px;
  }
  .desc__img {
    margin-top: 30px;
  }
  .specialists__txt {
    margin-top: 30px;
  }
  .specialists__item {
    padding: 20px;
    margin-bottom: 20px;
  }
  .specialists__img img {
    margin: 0 auto;
  }
  .specialists__txt {
    height: auto;
    display: block;
    padding-bottom: 0;
  }
  .specialists__txt__btn {
    margin-top: 30px;
    text-align: center;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {
  .nav__list {
    max-width: 540px;
  }
  .intro__sl__item {
    text-align: center;
  }
  .intro__sl .slick-track {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .intro__sl__item {
    height: auto;
  }
  .intro__img {
    position: static;
    padding-top: 25px;
  }
  .intro__img img {
    margin: 0 auto;
  }
  .intro__sl__item .container {
    min-height: 510px;
  }
  .intro__head img {
    margin: 0 auto;
  }
  .intro__txt {
    font-size: 26px;
  }
  .intro__txt sup {
    font-size: 15px;
  }
  .intro__big {
    border: 0;
    margin-top: 5px;
  }
  .preparation {
    padding: 45px 0;
  }
  .title__h2 {
    font-size: 28px;
  }
  .tabs__list__name:not(:last-child) {
    margin-right: 0;
    margin-bottom: 6px;
  }
  .information__sl .slick-arrow {
    top: -70px;
  }
  .footer .row [class*='col']:last-child {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 60%;
            flex: 0 0 60%;
    max-width: 60%;
  }
  .footer__contraindications {
    font-size: 24px;
  }
  .info {
    background-position: 0 0;
  }
  .info-title {
    font-size: 20px;
    padding: 10px 10px;
  }
  .faq .title {
    text-align: center;
  }
  .about__txt br {
    display: none;
  }
  .modal_login .modal__box {
    padding: 40px 15px 50px;
  }
  .modal_login .title__txt {
    margin-top: 20px;
  }
  .modal__group {
    margin-top: 30px;
  }
}

@media only screen and (max-width : 576px) {
  .preparation__box {
    padding: 45px 30px;
  }
  .information .title {
    text-align: center;
  }
  .information__sl .slick-dots {
    bottom: -45px;
  }
  .information__sl .slick-dots li {
    margin: 0 10px;
    width: 10px;height: 10px;
  }
  .information__sl .slick-dots li button {
    padding: 0;
    background: #fff;
    border-radius: 50%;
    width: 100%;height: 100%;
  }
  .information__sl .slick-dots li button:before {
    content: none;
  }
  .information__sl .slick-dots li.slick-active button {
    background: #e5860f;
  }
  .footer .row [class*='col']:not(:first-child) {
    display: none;
  }
  .footer .row [class*='col'] {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .footer__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .footer__text {
    margin-bottom: 30px;
  }
  .footer__bot {
    margin-top: 30px;
  }
  .info .row {
    min-height: 390px;
  }
  .info__box {
    text-align: center;
  }
  .info__img img {
    margin: 0 auto;
    width: 231px;height: 40px;
  }
  .info__txt {
    margin: 15px auto 0;
  }
  .info__name {
    font-size: 28px;
  }
  .about__list__item:not(:last-child) {
    margin-right: 0;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  .header .logo {
    margin-right: 15px;
  }
  .header .logo img {
    width: 150px;height: 27px;
  }
  .header .logo__txt {
    font-size: 7.3px;
  }
  .nav {
    top: 64px;
  }
  .intro__txt {
    font-size: 22px;
  }
  .intro__body {
    margin-top: 15px;
  }
  .intro__body br {
    display: none;
  }
  .preparation__box {
    padding: 30px 15px;
  }
  .preparation__box .title {
    text-align: center;
  }
  .title__h2 {
    font-size: 24px;
  }
  .tabs__list__name {
    padding: 0 10px;
  }
  .tabs__btn {
    padding-left: 20px;
  }
  .info__name {
    font-size: 24px;
  }
  .exercises__item {
    padding: 20px 15px;
  }
  .search {
    width: 260px;
  }
  .contacts__item {
    padding: 15px 10px;
    min-height: 95px;
  }
  .contacts__form .form {
    padding: 25px 10px;
  }
  .form__field input {
    height: 48px;
  }
  .form__field textarea {
    height: 140px;
  }
  .contacts__btn {
    width: 230px;
  }
  .about__list__item {
    font-size: 10px;
  }
  .specialists__txt h3 {
    font-size: 18px;
  }
  .specialists__txt h3 br {
    display: none;
  }
  .specialists__btn {
    font-size: 14px;
    padding: 6px 7px 6px 25px;
  }
  .specialists__btn .btn__arrow {
    margin-left: 15px;
  }
  .modal__group {
    display: block;
  }
  .modal__group .btn {
    max-width: 100%;
  }
  .modal__group .btn__white {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .modal__group .btn__blue {
    padding-right: 0;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .nav {
    display: block !important;
  }
}

 /* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  .container {
    min-width: 1170px;
  }
}